<script setup>
import BrandedBox from "@/components/BrandedBox.vue";
import {ref} from "vue";

const terms = ref(process.env.VUE_APP_TERMS);
</script>

<template>
  <branded-box>
    <div class="form-check mt-3">
      <input class="form-check-input" type="checkbox" id="terms" required>
      <label class="form-check-label" for="terms">
        Ich akzeptiere die
        <a :href="terms" target="_blank">
          Allgemeinen Geschäftsbedingungen
        </a>.
      </label>
    </div>
  </branded-box>
</template>
