<script setup>
import WrapperNeutral from "@/components/WrapperNeutral.vue";
import BrandedBox from "@/components/BrandedBox.vue";
import {onBeforeMount, ref} from "vue";
import store from "@/store";
import router from "@/router";
import {useRoute} from "vue-router";
import {useToast} from "vue-toastification";

const user = ref(store.getters.user);

onBeforeMount(async () => {
  if (!user.value) {
    await router.push('/');
  }

  const sessionId = useRoute().query.session_id;
  if (sessionId) {
    await store.dispatch('updateStripeSource', sessionId);
  }

  if (await store.dispatch('isStripeSourceRequired')) {
    useToast().error('Kreditkarte fehlt. Bitte auf Zahlung per Rechnung wechseln oder erneut versuchen.');
    await router.push('/account');
  }

  try {
    await store.dispatch('submitOrder');
  } catch (e) {
    useToast().error('Bestellung bereits aufgegeben. Möchten Sie eine weitere Kabine?');
    await router.push('/');
  }
});
</script>

<template>
  <wrapper-neutral v-if="user">
    <branded-box title="Bestellung erfolgreich!">
      Die Buchungsbestätigung wurde an {{ user.email }} versandt.
    </branded-box>
  </wrapper-neutral>
</template>