<script setup>

import ProgressCircles from "@/components/ProgressCircles.vue";
import {calcPrices} from "@/service";
import store from "@/store";

const emit = defineEmits(['submit'])
const props = defineProps({
  link: {
    type: String,
    required: true
  },
  title: {
    type: String,
    required: true
  },
  current: {
    type: Number
  },
  max: {
    type: Number,
    default: 3
  },
  summarize: {
    type: Boolean,
    default: true
  },
  action: {
    type: String,
    required: true
  },
  loading: {
    type: Boolean,
    default: false
  }
});

const interval = store.getters.interval;
const prices = calcPrices();

const submit = () => {
  emit('submit');
}
</script>

<template>
  <form @submit.prevent="submit">
    <nav class="navbar bg-secondary text-primary py-3 mx-auto">
      <div class="container-fluid mobile">
        <div>
          <a :href="props.link" v-if="link">
            <i class="bi bi-chevron-left"></i>
          </a>
          <span class="ms-5" v-html="props.title"></span>
        </div>
        <span>
          <progress-circles v-if="current" :current="props.current" :max="props.max"/>
        </span>
      </div>
    </nav>
    <div class="container-fluid mobile py-5">
      <slot/>
      <div class="py-5"/>
    </div>
    <div class="p-2 bg-secondary text-primary fixed-bottom">
      <div class="container-fluid mobile">
        <div class="row">
          <div class="col-6">
            <div v-if="props.summarize">
              <span class="fs-4">{{ prices?.monthly.toFixed(2) }} CHF/Monat</span>
              <br>
              <span v-if="interval !== 1">{{ prices?.prepayment.toFixed(2) }} CHF alle {{ interval }} Monate</span>
            </div>
          </div>
          <div :class="`d-grid col-${summarize ? '6' : '12'} fs-${summarize ? '6' : '3'}`">
            <button class="btn btn-primary btn-lg text-uppercase" type="submit" v-html="props.action"
                    :disabled="loading"></button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>