<script setup>
import store from "@/store";
import BrandedBox from "@/components/BrandedBox.vue";

const {area, volume, length, width, height} = store.getters.unit;
</script>

<template>
  <branded-box title="Kabine">
    <table style="width:100%">
      <tr>
        <td class="align-top"><i class="bi bi-arrows-angle-expand"></i></td>
        <td style="width:100%">
          <b>Grösse</b><br>
          {{ volume.toFixed(2) }} m³,  {{ area.toFixed(2) }} m²<br>
          L: {{ length.toFixed(2) }} m ×
          B: {{ width.toFixed(2) }} m ×
          H: {{ height.toFixed(2) }} m
        </td>
      </tr>
      <tr>
        <td class="align-top"><i class="bi bi-geo-alt-fill"></i></td>
        <td>
          <b>Standort</b><br>
          <span style="white-space: pre-wrap">{{ store.getters.site.address }}</span>
        </td>
        <td></td>
      </tr>
    </table>
  </branded-box>
</template>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 1em;
}
</style>