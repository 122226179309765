import {createApp} from 'vue'
import App from './App.vue'
import '../styles/customTheme.scss';
import 'bootstrap-icons/font/bootstrap-icons.css'
import "vue-toastification/dist/index.css";
import router from "@/router";
import Toast from "vue-toastification";

const app = createApp(App)
    .use(router)
    .use(Toast);
router.isReady().then(() => app.mount('#app'));