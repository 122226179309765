<script setup>
import BrandedBox from "@/components/BrandedBox.vue";
import {ref} from "vue";
import store from "@/store";
import {useToast} from "vue-toastification";

const emit = defineEmits(['login-link-hide']);
const email = ref(null);
const sendLink = async () => {
  try {
    await store.dispatch('requestLoginLink', email.value);
    await useToast().error('Wir haben Ihnen eine E-Mail mit einem Login-Link zugestellt.');
  } catch (error) {
    await useToast().error('Unbekannte E-Mail-Adresse. Bitte legen Sie ein neues Konto an.');
  }
}
</script>

<template>
  <branded-box title="Login-Link anfordern">
    <p>Wir stellen Ihnen per E-Mail einen Link zu. Klicken Sie darauf, um sich anzumelden.</p>
    <form @submit.prevent="sendLink">
      <div class="input-group">
        <input type="email" id="email"
               class="form-control"
               placeholder="z. B., martina.weber@gmx.ch" required
               v-model.trim="email">
        <button class="btn btn-primary" type="submit">Link anfordern</button>
      </div>

    </form>
    <p class="mt-3"><a href="#" @click.prevent="emit('login-link-hide')">Zurück zur Registrierung</a></p>
  </branded-box>
</template>
