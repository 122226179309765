<script setup>
import UnitCard from "@/components/UnitCard.vue";
import {computed, onBeforeMount, onMounted, ref, watch} from "vue";
import store from "@/store";
import BrandedBox from "@/components/BrandedBox.vue";
import {discounts, getDiscount, spaceOptions} from "@/service";
import WrapperNeutral from "@/components/WrapperNeutral.vue";

const site = ref(null);

onBeforeMount(async () => {
  await store.dispatch('discardOrder');
});

const updateUnits = async () => {
  loading.value = true;
  units.value = await store.dispatch('loadUnits', site.value?.code)
  loading.value = false;
}

const loading = ref(true);
const sites = ref([]);
const units = ref([]);
onMounted(async () => {
  await store.dispatch('discardOrder');
  sites.value = await store.dispatch('loadSites');
  await updateUnits();
});

const discount = ref(getDiscount(1));
const space = ref(spaceOptions[0]);

const filteredUnits = computed(() =>
    units.value.filter(unit => space.value.from <= unit.volume && unit.volume <= space.value.to)
);

watch(site, updateUnits);
</script>

<template>
  <wrapper-neutral>
    <branded-box title="Ihre Buchung">
      <div class="row gy-3">
        <div class="col-12">
          <select class="form-select" v-model="site" v-if="sites.length > 1">
            <option disabled>Standort</option>
            <option :value="null">Alle Standorte</option>
            <option v-for="site in sites" :key="site.code" :value="site">{{ site.title }}</option>
          </select>
        </div>
        <div class="col-12">
          <select class="form-select" v-model="discount">
            <option disabled>Bindung & Rabatt</option>
            <option
                v-for="d in discounts"
                :key="d.interval"
                :value="d"
                v-html="`${d.title} (${d.interval === 1 ? 'kein': (d.pctOff + ' %')} Rabatt)`"
            />
          </select>
        </div>
        <div class="col-12">
          <select class="form-select" v-model="space">
            <option disabled>Platzbedarf</option>
            <option v-for="s in spaceOptions" :key="s.title" :value="s" v-html="s.title"/>
          </select>
        </div>
      </div>
    </branded-box>

    <branded-box v-if="loading">
      <span class="fs-4">Lade ..</span>
    </branded-box>

    <branded-box v-else-if="filteredUnits.length === 0 && units.length > 0">
      <span class="fs-4">Diese Grössen sind ausgebucht.
        <a href="#" class="text-white" @click.prevent="() => space = spaceOptions[0]">Alle Grössen anzeigen</a>
      </span>
    </branded-box>

    <branded-box v-else-if="units.length === 0 && site != null">
      <span class="fs-4">Dieser Standort ist ausgebucht.
        <a href="#" class="text-white" @click.prevent="() => site = null">Alle Standorte anzeigen</a>
      </span>
    </branded-box>

    <branded-box v-else-if="units.length === 0 && site == null">
      <span class="fs-4">Wir sind ausgebucht.</span>
    </branded-box>

    <div v-else class="row g-3 pb-5">
      <div class="col-6" v-for="unit in filteredUnits" :key="unit.id">
        <unit-card :site="site" :unit="unit" :discount="discount"/>
      </div>
    </div>
  </wrapper-neutral>
</template>