import {createRouter, createWebHistory} from 'vue-router';
import UnitView from '@/views/UnitView.vue';
import AccountView from '@/views/AccountView.vue';
import SummaryView from '@/views/SummaryView.vue';
import ThanksView from "@/views/ThanksView.vue";

const routes = [{
    path: '/',
    alias: '/unit',
    component: UnitView,
}, {
    path: '/account',
    component: AccountView,
}, {
    path: '/summary',
    component: SummaryView
}, {
    path: '/thanks',
    component: ThanksView
}, {
    path: '/:catchAll(.*)',
    redirect: {path: '/'}
}];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
