<script setup>

import {calcDiscountedPrice} from "@/service";
import store from "@/store";
import router from "@/router";
import {computed} from "vue";

const props = defineProps({
  site: {
    type: Object
  },
  unit: {
    type: Object,
    required: true
  },
  discount: {
    type: Object,
    required: true
  }
});

const select = async () => {
  await store.commit('site', props.site ?? (await store.dispatch('loadSite', props.unit.siteId)));
  await store.commit('unit', props.unit);
  await store.commit('interval', props.discount.interval);
  await router.push('/account')
}

const loading = computed(() => store.getters.loading);
</script>

<template>
  <div class="card p-3 text-primary">
    <div class="card-body">
      <h4 class="card-title text-center">
        {{ props.unit.volume.toFixed(2) }} m³ / {{ props.unit.area.toFixed(2) }} m²
        <span v-if="props.unit.beforePrice">
          <span class="badge bg-secondary text-primary ms-2">
            {{ discount.interval > 1 ? 'Doppel-Rabatt' : 'Aktion' }}
          </span>
        </span>
      </h4>
      <p class="card-text text-center">
        L: {{ props.unit.length.toFixed(2) }} m ×
        B: {{ props.unit.width.toFixed(2) }} m ×
        H: {{ props.unit.height.toFixed(2) }} m
      </p>
      <p class="card-text text-center">
        <span v-if="props.unit.beforePrice">
          <del>{{ props.unit.beforePrice.toFixed(2) }}</del>&nbsp;
        </span>
        <span v-if="discount.interval > 1">
          <del>{{ props.unit.defaultPrice.toFixed(2) }}</del>&nbsp;
        </span>
        <span :class="{'': props.unit.beforePrice || discount.interval > 1}">
          {{ calcDiscountedPrice(props.unit.defaultPrice, props.discount.pctOff).toFixed(2) }} CHF/Monat
        </span><br>
        <small v-if="discount.interval > 1">Vorauszahlung alle {{ discount.interval }} Monate</small>
      </p>
      <div class="d-grid">
        <button class="btn btn-secondary text-uppercase fs-6" @click.prevent="select" :disabled="loading">
          Wählen
        </button>
      </div>
    </div>
  </div>
</template>