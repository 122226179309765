import {createStore} from "vuex";
import {proxyApi, sgUserApi} from "@/api";
import VuexPersistence from 'vuex-persist'
import {getInsurance, getRandomString, invoiceCharges} from "@/service";
import {useToast} from "vue-toastification";

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
})

const store = createStore({
    plugins: [vuexLocal.plugin],
    getters: {
        user: state => state.user,
        site: state => state.site,
        unit: state => state.unit,
        start: state => state.start,
        bearer: state => state.bearer,
        interval: state => state.interval,
        billingMethod: state => state.billingMethod,
        coverage: state => state.coverage,
    },
    mutations: {
        address: (state, address) => state.address = address,
        person: (state, person) => state.person = person,
        billingMethod: (state, method) => state.billingMethod = method,
        bearer: (state, bearer) => state.bearer = bearer,
        start: (state, start) => state.start = start,
        coverage: (state, coverage) => state.coverage = coverage,
        interval: (state, interval) => state.interval = interval,
        site: (state, site) => state.site = site,
        unit: (state, unit) => state.unit = unit,
        user: (state, user) => state.user = user,
    },
    actions: {
        loadSites: async () => {
            return (await proxyApi.get('sites')).data;
        },
        loadSite: async (context, siteId) => {
            return (await proxyApi.get(`sites/${siteId}`)).data;
        },
        loadUnits: async (context, siteCode) => {
            return (await proxyApi.get('units', {params: {siteCode}})).data;
        },
        loadUser: async (context, bearer) => {
            const user = (await sgUserApi.get('user', {
                headers: {Authorization: `Bearer ${bearer}`}
            })).data;

            context.state.billingMethod = user.billingMethod;

            context.commit('user', user);
            context.commit('bearer', bearer);
        },
        registerUser: async (context) => {
            const {firstName, lastName, phone, email} = context.state.person;
            const password = getRandomString();
            const {addressLine1, zip, city} = context.state.address;
            const address = `${addressLine1 || ''}\n${zip || ''} ${city || ''}`;

            const user = (await sgUserApi.post('users', {
                firstName,
                lastName,
                phone,
                email,
                password,
                address,
                language: 'de'
            })).data;

            if (context.state.billingMethod === 'invoice') {
                await proxyApi.post('invoice', {
                    userId: user.id,
                });
                user.billingMethod = 'invoice';
            }

            context.commit('user', user);

            context.commit('bearer', (await sgUserApi.post('auth/token', null, {
                auth: {username: email, password: password}
            })).data.accessToken);
        },
        requestLoginLink: async (context, email) => {
            await sgUserApi.post("auth/forgot-password", {email});
        },
        login: async (context, payload) => {
            try {
                const bearer = (await proxyApi.post("login", payload)).data.accessToken;
                await context.dispatch('loadUser', bearer);
                await useToast().error('Sie wurden angemeldet.');
            } catch (e) {
                await useToast().error('Automatische Anmeldung fehlgeschlagen.');
            }
        },
        logout: async (context) => {
            await context.dispatch('discardOrder');
            context.commit('user', null);
            context.commit('bearer', null);
        },
        discardOrder: async (context) => {
            context.commit('site', null);
            context.commit('unit', null);
            context.commit('start', null);
            context.commit('interval', null);
        },
        submitOrder: async (context) => {
            if (!context.state.bearer) {
                throw Error('bearer token missing')
            }

            const payload = {
                siteId: context.state.unit.siteId,
                startDate: context.state.start,
                unitTypeId: context.state.unit.typeId,
                unitId: context.state.unit.id,
                submitNow: true,
                promoCode: context.state.interval > 1 ? String(context.state.interval) : null,
                products: {},
                prepayPeriods: context.state.interval
            };

            if (!context.state.billingMethod === 'default') {
                const productId = invoiceCharges[context.state.unit.siteId];
                payload.products[productId] = 1;
            }

            if (context.state.coverage) {
                const insurance = getInsurance(context.state.coverage);

                const productId = insurance?.productIds[context.state.unit.siteId]
                if (productId) {
                    payload.products[productId] = 1
                }
            }

            const order = (await sgUserApi.post('units/orders', payload, {
                headers: {Authorization: `Bearer ${context.state.bearer}`}
            })).data

            if (context.state.interval > 1) {
                await proxyApi.post('interval', {
                    id: order.id,
                    prepayPeriods: context.state.interval
                });
            }
        },
        createStripeSession: async (context) => {
            const {publishableKey} = (await sgUserApi.get('billing/stripe/settings', {
                headers: {Authorization: `Bearer ${context.state.bearer}`}
            })).data;

            const {id} = (await sgUserApi.post(`billing/stripe/session?returnUrl=${location.origin}/thanks`, null, {
                headers: {Authorization: `Bearer ${context.state.bearer}`}
            })).data;

            return {publishableKey, sessionId: id}
        },
        updateStripeSource: async (context, sessionId) => {
            await sgUserApi.post('billing/stripe/sources', {token: sessionId}, {
                headers: {Authorization: `Bearer ${context.state.bearer}`}
            });
        },
        isStripeSourceRequired: async (context) => {
            if (context.state.user.billingMethod === 'invoice') {
                return false;
            }

            const sources = (await sgUserApi.get('billing/stripe/sources?v=2', {
                headers: {Authorization: `Bearer ${context.state.bearer}`}
            })).data;

            const hasMethod = sources.cards?.length > 0 || sources.sepa_debits?.length > 0;
            return !hasMethod;
        },
    }
});

export default store
