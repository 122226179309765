<script setup>
import {DateTime} from "luxon";
import {ref} from "vue";

const terms = ref(process.env.VUE_APP_TERMS);
</script>

<template>
  <nav class="navbar navbar-expand-lg bg-secondary">
    <div class="container">
      <a class="navbar-brand py-4" href="https://www.welti-furrer.ch/lagerung/selfstorage">
        <img src="@/assets/logo.svg" alt="Welti-Furrer" width="246"/>
      </a>
    </div>
  </nav>
  <div class="container-fluid mobile tall mt-5">
    <slot/>
  </div>
  <footer class="container-fluid bg-dark text-light p-5" style="font-size:.8em">
    <div class="container-fluid mobile text-start">
      <div>© {{ DateTime.now().get('year') }} - Welti-Furrer - Der führende Umzugsspezialist für Ihren Privat- und
        Geschäftsumzug, Lagerung und Logistik
      </div>
      <div>
        <ul class="menu">
          <li class="menu-item">
            <a target="_blank" :href="terms">
              Allgemeine Geschäftsbedingungen
            </a>
          </li>
          <li class="menu-item">
            <a target="_blank" href="https://www.welti-furrer.ch/impressum-haftungsausschluss/">
              Impressum &amp; Haftungsausschluss</a>
          </li>
          <li class="menu-item">
            <i class="bi bi-code"/> <a href="https://karibu.com" target="_blank">By Karibu Self-Storage IT</a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>


<style scoped>
.tall {
  min-height: 80vh;
}
</style>