import {DateTime} from "luxon";
import store from "@/store";

export const spaceOptions = [{
    title: 'Alle Grössen',
    from: 0,
    to: 1000
}, {
    title: 'Einige Möbel | 1 - 6m³',
    from: 0,
    to: 6
}, {
    title: '1 Zimmer | 7 - 10m³',
    from: 7,
    to: 10
}, {
    title: '2 Zimmer-Wohnung | 11 - 16m³',
    from: 11,
    to: 16
}, {
    title: '3 Zimmer-Wohnung | 14 - 22m³',
    from: 14,
    to: 22
}, {
    title: '4 Zimmer-Wohnung | 20 - 30m³',
    from: 20,
    to: 30
}, {
    title: '5 Zimmer-Wohnung | > 25m³',
    from: 25,
    to: 1000
}];

export const getInsurance = (coverage) => {
    const defaultInsurance = {
        coverage: 0,
        price: 0,
        title: 'Selbstversichert (Pflicht)'
    };

    try {
        return insuranceOptions.find(o => o.coverage === coverage) || defaultInsurance;
    } catch (error) {
        return defaultInsurance;
    }
}

export const discounts = [{
    title: '1 Monat',
    invoice: 'Monatsrechnung',
    interval: 1,
    pctOff: 0
}, {
    title: '3 Monate',
    invoice: 'Quartalsrechnung',
    interval: 3,
    pctOff: 5
}, {
    title: '6 Monate',
    invoice: 'Halbjahresrechnung',
    interval: 6,
    pctOff: 10
}, {
    title: '1 Jahr',
    invoice: 'Jahresrechnung',
    interval: 12,
    pctOff: 15
}];

export const getDiscount = (interval) => {
    return discounts.find(d => d.interval === interval);
}

export const calcDiscountedPrice = (price, discount) => {
    return price * (1 - discount / 100);
}

// 619b4a1063fab1001601a8f8 = rostock
export const insuranceOptions = [{
    title: "5'000 CHF Warenwert\t(+2.5 CHF/Monat)",
    coverage: 5000,
    price: 2.5,
    productIds: {
        '619b4a1063fab1001601a8f8': '64df5555330e67001461d20e', // dach-test, rostock
        '64841f1765700b0014b64ea1': '651acbee01d970001444ee1c'  // welti-furrer, bern
    }
}, {
    title: "10'000 CHF Warenwert\t(+5 CHF/Monat)",
    coverage: 10000,
    price: 5,
    productIds: {
        '619b4a1063fab1001601a8f8': '64df556c330e67001461d21d', // dach-test, rostock
        '64841f1765700b0014b64ea1': '651acc0301d970001444ef78'  // welti-furrer, bern
    }
}, {
    title: "25'000 CHF Warenwert\t(+12.5 CHF/Monat)",
    coverage: 25000,
    price: 12.5,
    productIds: {
        '619b4a1063fab1001601a8f8': '64df558c330e67001461d23e', // dach-test, rostock
        '64841f1765700b0014b64ea1': '651acc1801d970001444f381', // welti-furrer, bern
    }
}, {
    title: "50'000 CHF Warenwert\t(+25 CHF/Monat)",
    coverage: 50000,
    price: 25,
    productIds: {
        '619b4a1063fab1001601a8f8': '64df55a0330e67001461d250', // dach-test, rostock
        '64841f1765700b0014b64ea1': '651acc2c01d970001444f574'  // welti-furrer, bern
    }
}];

export const calcPrices = () => {
    const interval = store.getters.interval;

    const discount = getDiscount(interval);

    const deposit = store.getters.unit.defaultDeposit;

    const monthly = calcDiscountedPrice(store.getters.unit.defaultPrice, discount.pctOff);
    const prepayment = monthly * interval;

    const insurance = getInsurance(store.getters.coverage).price * interval;
    const processing = store.getters.billingMethod === 'default' ? 0 : 5 * interval;

    const total = prepayment + insurance + deposit + processing;

    return {deposit, monthly, prepayment, discount, insurance, processing, total}
}

export const getRandomString = () => {
    return Math.random().toString(36).substring(2);
}

export const formatRentalPeriod = () => {
    const dtStart = DateTime.fromISO(store.getters.start);
    const dtEnd = DateTime.fromISO(store.getters.start)
        .plus({months: store.getters.interval})
        .minus({days: 1});

    let formattedStart, formattedEnd

    if (dtStart.year !== dtEnd.year) {
        formattedStart = dtStart.setLocale('de-ch').toLocaleString(DateTime.DATE_FULL);
        formattedEnd = dtEnd.setLocale('de-ch').toLocaleString(DateTime.DATE_FULL);
    } else if (dtStart.month !== dtEnd.month) {
        formattedStart = dtStart.setLocale('de-ch').toFormat('d. MMMM')
        formattedEnd = dtEnd.setLocale('de-ch').toLocaleString(DateTime.DATE_FULL);
    } else {
        formattedStart = dtStart.setLocale('de-ch').toFormat('d.');
        formattedEnd = dtEnd.setLocale('de-ch').toLocaleString(DateTime.DATE_FULL);
    }

    return `${formattedStart} - ${formattedEnd}`;
}

export const invoiceCharges = {
    '5fb3f09c6b205900175b41b4': '6519183fd384cf00142798c2', // dach-test, central
    '6020ca520198f70015bd0cbc': '6519185f09da5e0014920764', // dach-test, parkhausen
    '619b4a1063fab1001601a8f8': '6519187bd384cf00142798ea', // dach-test, rostock
    '64841f1765700b0014b64ea1': '651acbbf01d970001444ea1b'  // welti-furrer, bern
}