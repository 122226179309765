<script setup>
import WrapperStep from "@/components/WrapperStep.vue";
import BrandedBox from "@/components/BrandedBox.vue";
import store from "@/store";
import {computed, onBeforeMount, ref} from "vue";
import BookingDetails from "@/components/BookingDetails.vue";
import router from "@/router";
import {calcPrices, formatRentalPeriod} from "@/service";
import BookingTerms from "@/components/BookingTerms.vue";
import {loadStripe} from "@stripe/stripe-js";
import {useToast} from "vue-toastification";

const unit = computed(() => store.getters.unit);
const user = computed(() => store.getters.user);

let prices;

onBeforeMount(async () => {
  if (!unit.value) {
    useToast().error('Bitte wählen Sie eine Kabine.');
    await router.push('/');
    return;
  }

  if (!user.value) {
    useToast().error('Bitte melden Sie sich an.');
    await router.push('/');
  }

  prices = calcPrices();
})

const loading = ref(false);
const submit = async () => {
  loading.value = true;

  if (await store.dispatch('isStripeSourceRequired')) {
    const stripeSettings = await store.dispatch('createStripeSession');
    const stripe = await loadStripe(stripeSettings.publishableKey);
    await stripe.redirectToCheckout({sessionId: stripeSettings.sessionId});
  } else {
    await router.push('/thanks');
  }

  loading.value = false;


}

const terms = ref(process.env.VUE_APP_TERMS);
</script>

<template>
  <wrapper-step
      link="/account"
      title="Schritt 3: Zusammenfassung"
      action="Zahlungspflichtig bestellen"
      :current="3"
      :summarize="false"
      :loading="loading"
      v-if="unit && user"
      @submit="submit"
  >
    <booking-details/>

    <branded-box title="Bestellung" v-if="prices">
      <div class="pb-5">
        <h6>{{ formatRentalPeriod() }}</h6>
        <table class="table my-5">
          <thead>
          <tr>
            <th>Leistung</th>
            <th class="text-end">Preis</th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="prices?.deposit > 0">
            <td>Kaution<br><small>einmalig, Erstattung bei Auszug</small></td>
            <td class="text-end">{{ prices.deposit.toFixed(2) }} CHF</td>
          </tr>
          <tr>
            <td>Miete<br>
              <small>
                <span v-if="prices.discount.interval === 1">monatlich</span>
                <span v-else>{{ prices.discount.pctOff }} % Rabatt</span>
              </small></td>
            <td class="text-end">{{ prices.prepayment.toFixed(2) }} CHF</td>
          </tr>
          <tr v-if="store.getters.coverage > 0">
            <td>Versicherung<br><small>Deckungssumme bis {{ store.getters.coverage }}
              CHF</small></td>
            <td class="text-end">{{ prices.insurance.toFixed(2) }} CHF</td>
          </tr>
          <tr v-if="store.getters.billingMethod === 'invoice'">
            <td>Rechnungsgebühr<br><small>Für manuelle Zahlungserfassung</small></td>
            <td class="text-end">{{ prices.processing.toFixed(2) }} CHF</td>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <td><span class="fs-4">Summe</span></td>
            <td class="text-end"><span class="fs-4">{{ prices.total.toFixed(2) }} CHF</span>
            </td>
          </tr>
          </tfoot>
        </table>
        <p>Der unbefristete Mietvertrag wird <span
            v-if="store.getters.interval === 1">monatlich</span>
          <span v-else>alle {{ store.getters.interval }} Monate</span> erneuert. Mehr dazu in den <a
              :href="terms">AGB</a>.
        </p>
      </div>
    </branded-box>

    <booking-terms/>
  </wrapper-step>
</template>