<script setup>
import WrapperStep from "@/components/WrapperStep.vue";
import store from "@/store";
import {useToast} from "vue-toastification";
import RentalManager from "@/components/RentalManager.vue";
import router from "@/router";
import {computed, onBeforeMount, ref} from "vue";
import BookingInsurance from "@/components/BookingInsurance.vue";
import BookingDetails from "@/components/BookingDetails.vue";

const loading = ref(false);
const user = computed(() => store.getters.user);

onBeforeMount(async () => {
  if (!store.getters.interval || !store.getters.unit || !store.getters.site) {
    await router.push('/');
  }
});

const submit = async () => {
  loading.value = true;

  if (!user.value) {
    try {
      await store.dispatch('registerUser');
    } catch (error) {
      loading.value = false;
      useToast().error('E-Mail-Adresse bereits registriert. Fordern Sie einen Login-Link an.');
      return;
    }
  }

  await router.push('/summary');
  loading.value = false;
}
</script>

<template>
  <wrapper-step
      link="/unit"
      title="Schritt 2: Rechnungsdaten"
      action="Nächster Schritt"
      :current="2"
      :loading="loading"
      v-if="store.getters.interval && store.getters.unit && store.getters.site"
      @submit="submit"
  >
    <booking-details/>
    <rental-manager/>
    <booking-insurance/>

  </wrapper-step>
</template>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 1em;
}
</style>