<script setup>
import {onMounted, ref, watch} from "vue";
import BrandedBox from "@/components/BrandedBox.vue";
import {insuranceOptions} from "@/service";
import store from "@/store";

const coverage = ref(10000);
const updateCoverage = () => store.commit('coverage', coverage.value);
watch(coverage, updateCoverage);
onMounted(updateCoverage);

const insure = ref(true);
watch(insure, () => {
  if (insure.value) {
    coverage.value = 10000;
  } else {
    coverage.value = null;
  }
});
</script>

<template>
  <branded-box title="Versicherung">
    <p>Versicherung gegen Diebstahl, Feuer und Wasserschäden wird empfohlen.</p>

    <div class="form-check">
      <input
          class="form-check-input"
          type="radio"
          name="insurance"
          id="insurance-yes"
          v-model="insure"
          :value="true"
      >
      <label class="form-check-label" for="insurance-yes">
        Ja, ich möchte eine Versicherung (empfohlen)<br>
      </label>
      <select class="form-select form-select-sm w-50 mt-2" v-model="coverage" :disabled="!insure">
        <option disabled hidden selected :value="null">Deckungssumme wählen</option>
        <option v-for="o in insuranceOptions" :key="o.coverage" :value="o.coverage">
          {{ o.title }}
        </option>
      </select>
    </div>
    <div class="form-check mt-3">
      <input class="form-check-input" type="radio" name="insurance" id="insurance-no" v-model="insure"
             :value="false">
      <label class="form-check-label" for="insurance-no">
        Nein, ich habe eine Versicherung und werde im Schadensfall keine Ansprüche gegenüber Welti-Furrer erheben.
      </label>
    </div>
  </branded-box>
</template>