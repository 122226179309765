<script setup>

import {ref, watch} from "vue";
import store from "@/store";
import BrandedBox from "@/components/BrandedBox.vue";

// person
const firstname = ref(null);
const lastname = ref(null);
const phone = ref(null);
const email = ref(null);

const updatePerson = () => {
  store.commit('person', {
    firstName: firstname.value,
    lastName: lastname.value,
    phone: phone.value,
    email: email.value,
  });
};

watch(firstname, updatePerson);
watch(lastname, updatePerson);
watch(phone, updatePerson);
watch(email, updatePerson);

// address
const addressLine1 = ref(null);
const zip = ref(null);
const city = ref(null);

const updateAddress = () => {
  store.commit('address', {
    addressLine1: addressLine1.value,
    zip: zip.value,
    city: city.value,
  });
}

watch(addressLine1, updateAddress);
watch(zip, updateAddress);
watch(city, updateAddress);

const emit = defineEmits(['login-link-show']);
</script>

<template>
  <branded-box title="Ihre Daten">

    <div class="row gy-3">
      <div class="col-6">
        <label for="firstname" class="form-label">Vorname</label>
        <input type="text" class="form-control" id="firstname" placeholder="z. B., Martina" required
               v-model.trim="firstname" tabindex="0">
      </div>
      <div class="col-6">
        <label for="lastname" class="form-label">Nachname</label>
        <input type="text" class="form-control" id="lastname" placeholder="z. B., Weber" required
               v-model.trim="lastname">
      </div>
      <div class="col-6">
        <label for="email" class="form-label">E-Mail-Adresse</label>
        <input type="email" class="form-control" id="email"
               placeholder="z. B., martina.weber@gmx.ch" required
               v-model.trim="email">
      </div>
      <div class="col-6">
        <label for="phone" class="form-label">Telefonnummer</label>
        <input type="tel" class="form-control" id="phone" placeholder="z. B., 044 44 12 34" required
               v-model.trim="phone">
      </div>
      <div class="col-12">
        <label for="address" class="form-label">Adresse</label>
        <input
            type="text"
            class="form-control"
            id="address"
            placeholder="z. B., Seestrasse 59"
            required
            v-model.trim="addressLine1"
        >
      </div>

      <div class="col-4">
        <label for="zip" class="form-label">PLZ</label>
        <input
            type="text"
            class="form-control"
            id="zip"
            placeholder="z. B., 8700"
            required
            v-model.trim="zip"
        >
      </div>

      <div class="col-8">
        <label for="city" class="form-label">Ort</label>
        <input
            type="text"
            class="form-control"
            id="city"
            placeholder="z. B., Küsnacht"
            required
            v-model.trim="city"
        >
      </div>
      <div class="col-12">
        <div class="form-text" id="basic-addon4">Schon mal gebucht? <a href="#"
                                                                       @click.prevent="emit('login-link-show')">Login-Link
          anfordern</a></div>
      </div>
    </div>
  </branded-box>
</template>